<template>

<br>
<br>
<br>
<br>
<br>


<div class="container-fluid datawindow p-0" id="datawindow">
<div class="container-fluid"> 
  <div class="jumbotron mt-3 pt-3">
    <div class="row" style="min-height:500px;">
      <div class="col-md-2 border-info border-right d-none d-lg-block" style="border-right:solid; padding-left:0px">
        <ul style="padding-left:0px;" class="text-center">
                        <li id="hand2note" @click="manual('hand2note')" style="font-size:20px; margin-bottom:20px;"> Hand2Note </li>
                        <li id="purchasing" @click="manual('purchasing')" style="font-size:20px; margin-bottom:20px;"> Purchasing </li>
                        <li id="install" @click="manual('install')" style="font-size:20px; margin-bottom:20px;"> Install </li>
                        <li id="config" @click="manual('config')" style="font-size:20px; margin-bottom:20px;"> Config </li>
                        <li id="panel" @click="manual('panel')" style="font-size:20px; margin-bottom:20px;"> Panel </li>
                        <li id="stats" @click="manual('stats')" style="font-size:20px; margin-bottom:20px;"> Stats </li>
                        <li id="auto" @click="manual('auto')" style="font-size:20px; margin-bottom:20px;"> Auto-Gen </li>
                        <li id="boards" @click="manual('boards')" style="font-size:20px; margin-bottom:20px;"> Boards </li>
                        <li id="hands" @click="manual('hands')" style="font-size:20px; margin-bottom:20px;"> Hands </li>
                        <li id="sizings" @click="manual('sizings')" style="font-size:20px; margin-bottom:20px;"> Sizings </li>
                        <li id="research" @click="manual('research')" style="font-size:20px; margin-bottom:20px;"> Range Research </li>
                        <li id="profiling" @click="manual('profiling')" style="font-size:20px; margin-bottom:20px;"> Profiling </li>
                        <li id="troubleshooting" @click="manual('troubleshooting')" style="font-size:20px; margin-bottom:20px;"> Troubleshooting </li>

        
        </ul>
      </div>
        <div class="col-md-10" style="padding-left:40px;"> 
       <nav class="navbar navbar-expand-lg navbar-dark bg-dark">    
       <button class="navbar-toggler mr-auto" type="button"  
                                              data-toggle="collapse" data-target="#manualnav" 
                                              aria-controls="navbarNav" aria-expanded="false" 
                                              aria-label="Toggle navigation" 
                                              style="position:fixed; z-index:1; margin-left:-65px;">
        
          <span class="navbar-toggler-icon"></span>
          </button>
     
      </nav>
          <div id="manualtext"></div>
          
          
          
          
          
      </div>
      </div>
   </div>
</div>
</div>
      <div class="collapse navbar-collapse bg-dark" id="manualnav" style="position:fixed;
                                                                          top:180px; left:75px; z-index:1; 
                                                                          border:dotted; border-color:aqua; opacity:1; 
                                                                          border-width: 1px;
                                                                          max-height:500px; overflow:auto; margin-left:-65px; width:175px;">
              <ul class="text-center navbar-nav d-block d-lg-none">
                        <li class="nav-item" id="hand2note" @click="manual('hand2note')" style="font-size:20px; margin-bottom:20px;"> Hand2Note </li>
                        <li class="nav-item" id="purchasing" @click="manual('purchasing')" style="font-size:20px; margin-bottom:20px;"> Purchasing </li>
                        <li class="nav-item" id="install" @click="manual('install')" style="font-size:20px; margin-bottom:20px;"> Install </li>
                        <li class="nav-item" id="config" @click="manual('config')" style="font-size:20px; margin-bottom:20px;"> Config </li>
                        <li class="nav-item" id="panel" @click="manual('panel')" style="font-size:20px; margin-bottom:20px;"> Panel </li>
                        <li class="nav-item" id="stats" @click="manual('stats')" style="font-size:20px; margin-bottom:20px;"> Stats </li>
                        <li class="nav-item" id="auto" @click="manual('auto')" style="font-size:20px; margin-bottom:20px;"> Auto-Gen </li>
                        <li class="nav-item" id="boards" @click="manual('boards')" style="font-size:20px; margin-bottom:20px;"> Boards </li>
                        <li class="nav-item" id="hands" @click="manual('hands')" style="font-size:20px; margin-bottom:20px;"> Hands </li>
                        <li class="nav-item" id="sizings" @click="manual('sizings')" style="font-size:20px; margin-bottom:20px;"> Sizings </li>
                        <li class="nav-item" id="research" @click="manual('research')" style="font-size:20px; margin-bottom:20px;"> Range Research </li>
                        <li class="nav-item" id="profiling" @click="manual('profiling')" style="font-size:20px; margin-bottom:20px;"> Profiling </li>
                        <li class="nav-item" id="troubleshooting" @click="manual('troubleshooting')" style="font-size:20px; margin-bottom:20px;"> Troubleshooting </li>

        </ul>
         </div>




</template>

<script>
export default {
        mounted() {
            console.log('Manual component has been mounted');
        },

        methods: {
            manual(chapter) {
                                  $("#manualtext").load("../html/manual/" + chapter + ".html");
                                  $("#manualnav").collapse("hide");
                                  //$("#manualtext")[0].scrollIntoView();
                            },
        }
}
</script>

<style>

</style>