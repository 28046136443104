<template>



    <div class="container" id="creditswindow">
  <div class="jumbotron text-center overflow-auto">
    <div class="border border-info w-50 mx-auto rounded" id="cellamount">
    <h4 class="d-inline">Fuel Cells:</h4> <p class="d-inline big"> {{user.credits}} </p>
      </div>
    <br> <br>
<p class="text-left"> Fuel cells are an indication of which craft (HUDs) are available for download and activation.
    For example, with 30 fuell cells, all HUDs requiring 30 fuel cells or less will be available.
    Fuell cells can be purchased at a rate of $10/cell or earned through other methods such as referrals and H2N subscriptions.
    </p> <br>
    <div class="border border-info w-50 mx-auto rounded" id="cellamount">
    <h4 class="d-inline">Purchase Fuel Cells </h4>
      </div>
<br> <br>
    <p>Cliick to purchase {{fuelCells}} fuel cells at a cost of ${{fuelCost}}.</p>
<form class="form-group" id="purchasefuel">
<input type="float" placeholder="Number of Fuel Cells" id="fuelCost" class="form-control mx-auto" style="width:300px;" v-model="fuelCells">
</form>

<paypal-purchase :user='user' :amount='fuelCost' @purchase-complete="purchaseComplete"> </paypal-purchase>



   </div>
  </div>










</template>

<script>
import PaypalPurchase from './../Utilities/PaypalPurchase.vue';
export default {
    mounted(){
      console.log('Credits component mounted');
    },

    data(){
      return {
        fuelCells : 0
      }
    },

    computed : {
      fuelCost(){
        return this.fuelCells * 10; // Price should not necessarily be hard-coded
      }
    },

    methods : {
      purchaseComplete(fuelCells){

                 let element = document.getElementById('paypal-button-container');
                 element.innerHTML = '';
                 element.innerHTML = `
                 <h3>Credits Added!</h3>`;
                 console.log(fuelCells + ' added');
                 this.user.credits += fuelCells;
      }
    },

    props: {
      user : Object
    },

    components : {
      PaypalPurchase
    }
}
</script>

<style>

</style>
