<template>
    <!-- This is the window for contating support via email -->
  <div class="container" id="supportwindow">
  <div class="jumbotron text-center overflow-auto">
    
  <h2> Contact Support </h2>
   
    <p class="big"><span style="color:aqua;">My Email:</span> {{Email}}</p>
     <br> <br>
    
  <div class="form-group w-75 mx-auto">
    
    
    <textarea class="form-control border-info" name="query" rows="20" cols="80" style="background-color:black; opacity:0.8; color:white;" v-model="message">

Questions for support.....

      Alternatively, send using your email client to support@pokerdeathstar.xyz

</textarea>
    <br> 
    <button class="btn btn-info" @click="sendMessage()"> Send Message </button>

    
    </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted(){
    console.log('Support component mounted');
  },

  data(){
    return {
      message : '',
      modalMessage : 'Support request submitted. Remember to check your junk folder'
    }
  },

  methods: {
    sendMessage(){
      this.axios.post('/post/support', {
                'message' : this.message
      }).then((response) => {
                var modal = {
                  message : 'Support request submitted. Remember to check your junk folder',
                  title : 'Submitted'
                }
                this.$emit('show-modal-event', modal);
      });
    }

  }

}
</script>

<style>

</style>