<template>

<div id="paypal-button-container" style="width:300px;" class="mx-auto"></div>

</template>

<script>
import axios from 'axios';
export default {

  props: {
    user : Object,
    resource : {
      type: String,
      default: null},
    amount : Number
  },

   data() {
       return {
           paypal : {},
           orderData : {}
       }
   },

  
   methods : {
       capture(orderData) {
           this.orderData = orderData;
           axios.post('/paypalcapture', {orderData : orderData, user : this.user}).then((response) => {
           this.$emit('purchaseComplete', response.data.fuelCells);
           //setTimeout(this.refresh(), 5000);
         })
       }
   },


    mounted() {
      let self = this;
 
      console.log('New paypal component has been mounted');


        let recaptchaScript = document.createElement('script');
        //recaptchaScript.setAttribute('src', 'https://www.paypal.com/sdk/js?client-id=ARpus7V6Dc-tSJrf34tmt90ZX6ByPA0oOH9U7H2dHVc7yH6r3VdoqhMDbpNRboFkOXoVzvdumiq2d603&currency=USD');// TEST
        recaptchaScript.setAttribute('src', 'https://www.paypal.com/sdk/js?client-id=AR8og3JAtj755_kB4J6o3JeCTjdX92N9BnFtLvYnTWjxT7NjY3iwwXVjohsfoyQUsZb38bqswOeQssFC&currency=USD');// LIVE
        recaptchaScript.onload = () => {
                this.paypal = window.paypal;
              
                this.paypal.Buttons({

        // Sets up the transaction when a payment button is clicked
        createOrder: function(data, actions) {
          return actions.order.create({
              payer : {
                email_address : self.user.email,
              name : {
                given_name : self.user.first_name,
                last_name : self.user.last_name
              }
            },

            purchase_units: [{
              reference_id : 'FUEL',
              amount: {
                value: self.amount
              },

             description : 'Death Star Fuel Cells'
            }],

            application_context : {
              brand_name : 'pokerdeathstar'
            }
          });s
        },

        // Finalize the transaction after payer approval
        onApprove: function(data, actions) {
          return actions.order.capture().then(function(orderData) {

                //console.log('Capture result', orderData, JSON.stringify(orderData, null, 2));
                var transaction = orderData.purchase_units[0].payments.captures[0];

                 let element = document.getElementById('paypal-button-container');
                 element.innerHTML = '';
                 element.innerHTML = `
                 <h3>Thank you for your payment!</h3>
                 <p> Adding your new permissions... </p>
                 <div class="spinner-border text-secondary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>`;

                self.capture(orderData);
            // Or go to another URL:  actions.redirect('thank_you.html');
          });
        }
      }).render('#paypal-button-container'); 
              
            };
        document.head.appendChild(recaptchaScript);
    },

    emits : [
      'purchaseComplete'
    ]
}
</script>

<style>

</style>

