require('./bootstrap');
import { createApp } from 'vue';
import { defineAsyncComponent } from "vue";
import Versions from './components/Versions.vue';
import Manual from './components/Manual.vue';
import Account from './components/Account.vue';
//import Admin from './components/Admin.vue';
import axios from 'axios';
import VueAxios from 'vue-axios';

const Admin = defineAsyncComponent(() =>
  import("./components/Admin.vue")
);

createApp({
    components: {
        'Versions' : Versions,
        'Manual' : Manual,
        'Account' : Account,
        'Admin' : Admin
    }
}).use(VueAxios, axios)
.mount('#app');
