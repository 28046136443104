<template>
<div class="component-wrapper" :style="{opacity : opacity}">
<div class="container-fluid">
  <br>
<br> <br> <br> <br>
  <div class="jumbotron text-left" style="padding-top:5px;">
    
 
    <div class="row">
    <div class="col-8">
      
      
    
    <h4 style="margin-top:50px;">
   Welcome {{user.first_name}}
    </h4>
 <h2 class="d-inline"> Account: </h2> <p class="big d-inline"> {{user.email}}</p>
    
    <br> <br>
<div v-if="user.h2nemail">
  <h2 class="d-inline"> Hand2Note Email: </h2> <p class="big d-inline"> {{user.h2nemail}} </p> 
   <br> <br>
  <p class="green">
     Please contact support if you wish to change your H2N email.
  </p>
      
      
      </div>
    <div v-else> <h4 class="d-inline">Please enter your H2N email: </h4> <br>
      <input type="text" v-model="h2nemail" placeholder="H2N email" class="form-control form-group mt-3" style="width:275px">
      <button class="btn btn-info d-inline" @click="submitEmail()"> Submit </button>
   </div>
    
  </div>
  <div class="col-4" style="height:286px;">
    
     <image src="images/logo.jpg" alt="death star image" style="width:225px; margin-top:10%;" class="d-none d-lg-block float-right"></image>
    
    
      </div>
    
    
  </div>
    
    
    
 </div> 
    
    
    
  </div>

<div class="container text-center rounded bg-dark" style="opacity:0.90;">
  <div class="row">
    <div class="col-3">
      <button class="btn btn-info dsbtn" id="myhuds" @click="setView('myhuds')"> My HUDs</button>
    </div>
    <div class="col-3"> 
    <button class="btn btn-info dsbtn" id="myrefer" @click="setView('myrefer')"> Referrals </button>
    </div>
    <div class="col-3">
    <button class="btn btn-info dsbtn" id="mysupport" @click="setView('mysupport')"> Support </button>
    </div>
     <div class="col-3">
    <button class="btn btn-info dsbtn" id="mycredits" @click="setView('mycredits')"> Credits </button>
    </div>
    </div>
    </div>
  <br> <br> <br>

  



<div class="container">
    <Huds v-if="view == 'myhuds'" @show-modal-event="showModal($event)" :user="user"></Huds>
    <Credits v-if="view == 'mycredits'" :user="user"></Credits>
    <Referrals v-if="view == 'myrefer'" @show-modal-event="showModal($event)" :user="user"></Referrals>
    <Support v-if="view == 'mysupport'" @show-modal-event="showModal($event)"></Support>

</div>

</div>
  
  
    <Modal v-show="displayModal" @close-modal-event="hideModal()" :message='modal.message' :title="modal.title"></Modal>
  
</template>

<script>
import Credits from './Account/Credits.vue';
import Huds from './Account/Huds.vue';
import Referrals from './Account/Referrals.vue';
import Support from './Account/Support.vue';
import Modal from './Utilities/Modal.vue';
export default {
    mounted() {
        console.log('Account component mounted');
        this.refresh();
    },

    data() {
        return {
            user : {},
            hudsdata : {},
            view : 'myhuds',
            h2nemail : '',


            displayModal : false,
            modal : {},
            modalMessage : 'default modal message',
            modalTitle : 'received'
        }
    },

    methods: {
        refresh(){
            this.axios.get('/get/userdata').then((response) => {
                this.user = response.data.user;
            });

            this.axios.get('/get/hudsdata').then((response) => {
                this.hudsdata = response.data;
            });
        },

        setView(view){
            this.view = view;
        },

        submitEmail(){
            this.axios.post('/store/h2nemail', {
                  'h2nemail' : this.h2nemail
            }).then((response) => {

              this.showModal({
                'message' : 'H2N email Updated Successfully to ' + this.h2nemail + '.',
                'title' : 'Successful'
              });

                console.log(response.data);
                this.user.h2nemail = this.h2nemail;
            })

        },

        showModal(modal){
          this.modal = modal;
            this.displayModal = true;
            this.opacity = 0.3;
        },

        hideModal(){
            this.displayModal = false;
            this.opacity = 1;
         }
    },

    components : {
      'Huds' : Huds,
      'Referrals' : Referrals,
      'Support' : Support,
      'Credits' : Credits,
      'Modal' : Modal
    }

}
</script>

<style>

</style>