<template>
    <!-- This is the window for referral data -->
  <div class="container" id="referwindow">
  <div class="jumbotron text-center overflow-auto">
   <h2> Referrals</h2>
   <br>
   <div id="refertable" class="text-center">
   
   
<table class='table table-dark table-sm table-striped text-center'>
  <thead>
<tr>
<th>Time </th>
<th>Trans ID </th>
<th>Purchaser</th>
<th>Amount (USD) </th>
<th>Reward </th>
<th>Desc </th>
</tr>
</thead>
<tbody>
  <tr v-for="trans in refdata"> 
  <td> {{ trans.created_at.split('T')[0] }} </td>
  <td> {{ trans.id }} </td>
  <td> {{ trans.email }} </td>
  <td> {{ trans.amount }} </td>
  <td> {{ trans.amount * 0.25 }}</td>
  <td> {{ trans.item }} </td>
  </tr>
</tbody>
</table>

   
<table class='table table-dark w-50' style='margin:auto;'>
<tr>
<td>Total affiliate earnings:</td>
<td> {{ total }} </td>
</tr>
<tr>
<td>Total withdrawals:</td>
<td> {{ user.withdrawals }} </td>
</tr>

<tr>
<td class='cyan'>Account Balance:</td>
<td id='balance'> {{ balance }}</td>
</tr>
</table>
   
   
   
   </div>
   <br>
   <input type="number" class="form-control-sm" v-model="withdrawAmount" placeholder="Withdrawal Amount"><br><br>
   <input type="text" class="form-control" v-model="withdrawMethod" placeholder="Account details"><br>
   <button class="btn btn-info mt-2" @click='withdraw()'>
     Request Withdrawal
    </button>
    <br>
    <br>
    </div>
     <div class="jumbotron text-center overflow-auto">
    
     <h2> Referral Tools</h2>
    <br>
    <br>
<p>
  Give out the following link to your friends, on your twitch stream, or on you site.
  You will receive 25% of whatever they spend at www.pokerdeathstar.xyz. 
    </p>
 <h4>
   My refer id = www.pokerdeathstar.xyz?ref={{user.id}}
    </h4>
     <br>
     <br>
 <p>
   Use the following html code to add the DS banner to your page and collect a 25% affiliate fee. <br>
  </p> 
   <code style="font-size:20px;">
   &lta href="www.pokerdeathstar.xyz?ref={{user.id}}"&gt <br>
   &ltimg src="http://www.pokerdeathstar.xyz/images/banners/ds_banner_wide.jpg" alt="DS banner"&gt
   &lt/a&gt
  </code>
  <br>
  <br>     
  <br>    

     
      

<img src="http://www.pokerdeathstar.xyz/images/banners/ds_banner_wide.jpg" alt="DS banner">

    </div>

  </div>
  
</template>

<script>

export default {
  mounted(){
    console.log('Referrals component mounted');
    this.refresh();
  },

  data(){
    return {
      refdata : {},
      total : 0,
      withdrawAmount : 0,
      withdrawMethod : ''
    }
  },

  methods : {
    refresh(){
      this.axios.get('/get/refdata').then((response) => {
            this.refdata = response.data.transactions;
            this.total = response.data.total;
      });
    },

    withdraw(){
        this.axios.post('/store/withdrawrequest', {
                  'amount' : this.withdrawAmount,
                  'method' : this.withdrawMethod
        }).then((response) => {
                  console.log(response.data);
                     var modal = {
                  message : response.data,
                  title : 'Submitted'
                }
                this.$emit('show-modal-event', modal);
        });
               
                

    }
  },

  props : {
    user : Object
  },

  computed : {
    balance(){
      return this.total - this.user.withdrawals;
    }
  }

}
</script>

<style>

</style>