<template>

  <br> <br> <br> <br>
  <body>
    
 <div class='container w-100'>

 <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel" data-interval="false">
  <ol class="carousel-indicators">
    <li data-target="#carouselExampleCaptions" data-slide-to="0" class="active"></li>
    <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
    <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
    <li data-target="#carouselExampleCaptions" data-slide-to="3"></li>
  </ol>
   
   
   
  <div class="carousel-inner text-center" interval="false">
    
    
    
    <div class="carousel-item active w-100 h-65" style="opacity:0.9;">
      <img src="images/sw_TF.webp" @click="loadData('TF')" class="w-50 rounded mt-5"  alt="tie fighter HUD" id="shipimage">
       <br> <br> <br> <br> <br> <br> <br> <br> <br>
      <div class="carousel-caption">
        <h3 class="pt-5">Tie Fighter</h3>
        <p>Ultra lightweight interface for lag free basic tracking</p>
       <a href="#datawindow"><button type="button" @click="loadData('TF')" class="btn-info btn-sm databtn" style="margin-bottom:20px;">HUD Data</button></a>
      </div>
    </div>
    
    
    
    
    
    <div class="carousel-item w-100 h-65" style="opacity:0.9;">
      <img src="images/sw_TS.webp" @click="loadData('TS')" class="w-50 rounded mt-5" alt="tydirium shuttle HUD" id="shipimage">
      <br> <br> <br> <br> <br> <br> <br> <br> <br>
      <div class="carousel-caption">
        <h3 class="pt-5">Tydirium Shuttle</h3>
        <p>Midweight fully kitted HUD for smooth grinding experience</p>
       <a href="#datawindow"> <button type="button" @click="loadData('TS')" class="btn-info btn-sm databtn" style="margin-bottom:20px;">HUD Data</button> </a>
      </div>
    </div>
    
    
    
    
      <div class="carousel-item w-100 h-65" style="opacity:0.9;">
      <img src="images/sw_SD.webp" @click="loadData('SD')" class="w-50 rounded mt-5" alt="Star Destroyer HUD" id="shipimage">
        <br> <br> <br> <br> <br> <br> <br> <br> <br>
      <div class="carousel-caption">
        <h3 class="pt-5">Star Destroyer</h3>
        <p>Heavyweight grinding HUD for fast systems or a rich analysis toolset for all systems</p>
        <a href="#datawindow"><button type="button" @click="loadData('SD')" class="btn-info btn-sm databtn" style="margin-bottom:20px;">HUD Data</button></a>
      </div>
    </div>
    
    
  
    
    
    <div class="carousel-item w-100 h-65 text-center" style="opacity:0.9;">
      <img src="images/sw_DS.jpeg" @click="loadData('DS')" class="w-50 rounded mt-5"  alt="Death Star HUD" id="shipimage">
      <br> <br> <br> <br> <br> <br> <br> <br> <br>
      <div class="carousel-caption">
        <h3 class="pt-5">Death Star</h3>
        <p>Elite ultra-powerful analysis toolset. Recommended for off-table work only.</p>
      <a href="#datawindow">  <button type="button" @click="loadData('DS')" class="btn-info btn-sm databtn" style="margin-bottom:20px;" id="deathbtn">HUD Data</button> </a>
      </div>
    </div>
    
    
    
  </div>
  <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev" id="clickprev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next" id="clicknext">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>
      
      
    </div>
  

<div class="container datawindow" id="datawindow" style="display:none;">
<div class="container"> 
  <div class="jumbotron mt-3 pt-3">
    <div class="row" style="min-height:500px;">
      <div class="col-md-2 border-info border-right" style="border-right:solid; padding-left:0px">
        <ul style="padding-left:0px;" class="text-center">
                        <li id="overview" @click="loadOverview()" style="font-size:20px; margin-bottom:20px;"> Overview </li>
                        <li id="shots" @click="loadShots()" style="font-size:20px; margin-bottom:20px;"> Shots </li>
                        <li id="data" @click="loadHudData()" style="font-size:20px; margin-bottom:20px;"> Data </li>
        </ul>
 
      </div>
        <div class="col-md-10" id="datatext" style="padding-left:40px;">  </div>
      </div>
   </div>
</div>
 </div>
    
    
    
    
    
    
  </body>

</template>

<script>
export default {

    mounted() {
        console.log('loaded versions component');
    },

    data () {
        return {
            versionSelect : '',
            overviewLink : '',
            shotsLink : '',
            dataLink : ''
        }
    },


    methods: {
        loadData(hudName) {
                                 this.versionSelect = hudName;
                                 this.overviewLink = "../html/huddata/" + hudName + ".html";
                                 this.shotsLink = "../html/huddata/" + hudName + "slides.php";
                                 //this.shotsLink = "dsslideviewer.php?hudslides=" + hudName;  (If you want all slides from same source in future)
                                 this.dataLink = "../html/huddata/" + hudName + "data.html";
                                 $("#datawindow").fadeIn();
                                 $("#datatext").load(this.overviewLink);

        },

        loadShots() {  $("#datatext").load(this.shotsLink);  },

        loadHudData() {  $("#datatext").load(this.dataLink);  },

        loadOverview() { $("#datatext").load(this.overviewLink);  },
        
    }

}
</script>

<style>

</style>