<template>
  <!-- Modal -->
  <div class="bootstrap-modal-no-jquery">
    <div class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document" style="margin-top: 15%;">
        <div class="modal-content bg-dark" style="border-color:aqua;">
      <div class="modal-header" style="border-style:none;">
        <h4 class="modal-title" id="exampleModalLabel"> {{ title }} </h4>
        <button type="button" class="close" @click="closeModal()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center" style="color:white;" id="tstmessage">
                  <p style="font-size:18px;" v-html="message"></p>
      </div>
      <div class="modal-footer" style="border-style:none;">
        <button type="button" class="btn btn-info" @click="closeModal()">Ok, thanks!</button>
      </div>
    </div>
  </div>
</div>
</div>
<!-- End Modal -->
</template>

<script>
  export default {
    mounted() {
      console.log('Modal mounted');
    },
    methods: {
      closeModal() {
        this.$emit('close-modal-event');
      },
      saveChanges() {
        this.closeModal();
      }
    },

    data() {
      return {

      }
    },

    props: ['message', 'title']
  }
</script>

<style scoped>
  /* Override default value of 'none' */
  .modal {
    display: block;
  }
</style>
